@import "../../../assets/sass/mixins";
@import "../../../assets/sass/variables";

.info-main-heading {
  font-size: 26px;
  margin-bottom: 20px;
}

.profile-info {
  display: flex;
  align-items: center;
  font-weight: 500;

  > *:not(:last-child) {
    margin-right: 50px;
  }

  &-wrapper {
    margin-bottom: 40px;
  }

  &-name{
    font-size: 18px;
    font-family: Font-Semibold;
    color: $tabdetail;
  }
  &-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 40px  40px 40px;
    gap:25px;
    flex-basis: 74%;
  }
}

.title-bd-bt{
  padding-bottom: 12px;
  border-bottom: 1px solid $tabsActiveColor;
}

.quick-links{
  margin-bottom: 30px;
  &-list{
    max-height: 170px;
    overflow-y: auto;
    column-gap: 20px;
    row-gap: 4px;
    li{
      line-height: 2;
      font-family: 'Font-Medium';
    }
  }
}

.assigned-projects{
  flex-basis: 25%;
}

.patient-card {
  height: calc(100% - 40px);

  &-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    .ant-card {
      flex: auto;
    }
    .immunization-history {
      .ant-table .ant-table-tbody {
        max-height: 355px;
      }
    }
  }

  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .listitem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    padding: 12px 8px 12px 0;
    border-bottom: 1px solid $borderColor;

    svg{
      width:16px;
      height: 16px;
    }
    &:last-child{
      border-bottom: 0px;
      padding-bottom: 0px;
    }
  }

  &.cyan {
    .ant-card-body {
      overflow-y: auto;
      max-height: 400px;
    }
  }

  &.lifestyle {
    padding: 10px;
    font-size: 12px;
  }

  .pdf-icon {
    --size: 22px;
    width: var(--size);
    height: var(--size);
  }

  &.orange {
    .reviewlist {
      display: flex;
      align-items: center;
      font-size: 12px;
      word-break: break-word;
      text-align: left;
      margin-bottom: 0px;
      font-family: Font-Medium;
      color: $linkColor;
    }
  }
}

.pdf-viewer {
  overflow: hidden;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.lifetsyle {
  .ant-card {
    overflow-y: auto;
  }

  .aiButton {
    align-self: flex-end;
    min-width: 124px;
    max-width: 124px;
  }
}

.view-more-text {
  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.view-more-result {
  width: 100%;
  margin-top: auto;
  padding: 16px 0px;
  font-family: Font-Medium;
  font-size: 12px;

  p{
    color: $tabsActiveColor;
  }

  button {
    font-family: Font-Regular;
  }
}
.search-select-row {
  margin-top: 30px;
}
