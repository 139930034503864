@import "../../assets/sass/variables";
@import "../../assets/sass/mixins";

.project-detail-container{
  &-left{
    // padding: 30px;
    .tabs{
    padding:30px 30px 0 30px;
    &-content{
      padding: 0px 30px 30px 30px;
      overflow-y: auto;
      max-height: calc(100vh - 209px);
    }
    }
    &-title{
      @include font_size_color(16px, $titleColor, null, 0,Font-Semibold);
      svg{
        width:32px;
        height:32px;
        padding:8px;
        border-radius: 4px;
        &.icon-overview{
          color:$lightLilacColor;
          background: rgba($lightLilacColor, 0.3);
        }
        &.icon-config{
          color:$royalOrangeColor;
          background: rgba($royalOrangeColor, 0.3);
        }
      }
    }
    .status-tag{
      .ant-table {
        .ant-table-tbody {
          max-height: calc(100vh - 350px);
        }
      }
    }
  }
  &-right{
    background: $lightgrayColor;
    // padding: 40px 30px;
    height: calc(100vh - 82px);
    &-title{
      // padding: 30px;
      p{
        @include font_size_color(16px, $titleColor, null, 0,Font-Semibold);
      }
    }
    .team-member{
      &-container {
        overflow-y: auto;
        max-height:calc(100vh - 180px);
        // padding: 0 30px 30px;
      }
      &-card{
        padding: 20px 15px;
        background: $baseColor;
        border: 1px dashed $borderColor;
        border-radius: 4px;
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: 45px 1fr;
        span{
          margin-top: 7px;
        }
        .italic{
          color: $darkLilacColor;
        }
        .text_ellipsis{
          width: 186px;
          cursor: pointer;
        }
      }
    }    
  }
  .tabs {
    .ant-tabs-top {
        &> .ant-tabs-nav {
          &::before {
            border-bottom: 0px;
          }
          .ant-tabs-ink-bar{
            height:0px;
          }
        }
      }
    }
  }
.patients-tab {
  row-gap: 20px;
}

.recent-document {
  .client-table {
    .ant-table {
      .ant-table-tbody {
        max-height: calc(100vh - 325px);
        overflow-y: auto;
        display: block;
        word-break: break-all;
      }
    }
    .ant-table-container table {
      .ant-table-cell {
        &:nth-child(2),
        &:nth-child(3) {
          width: 200px;
        }
        &:nth-child(4),
        &:last-child {
          // width: 350px;
          text-align: center;
        }
      }
    }
    .status {
      border: 1px solid #f5f9ff;
      box-shadow: 0px 2px 15px rgb(0 0 0 / 5%);
      border-radius: 3px;
      display: inline-block;
      padding: 6px 10px;
      font-size: 12px;
      font-family: Font-Medium;
    }
  }
}
