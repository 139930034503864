// $bgColor: #1c1d22;
// $primaryColor: #e8ae55;
// $buttonHoverColor: #da8e19;
// $secondaryColor: #314e98;
// $textPrimaryColor: #ffffff;
$textSecondaryColor: #9e9e9e;
// $text-color1: #a4a5a7;
// $placeTextColor: #505053;
// $cardBgColor: #303136;
// $orange: #d87e14;
// $red: #c2334d;
// $blue: #4743db; 

// $lightgray: #ededed;

$tabdetail: #1e293b;
$green: #44c658;
$bgBaseColor: #222328;
$baseColor: #ffffff;
$baseTextColor: #635F6A;
$titleColor: #301934;
$smTitleColor: #02462d;
$linkColor: #2E5EBC;
$borderColor: #e7ecf4;
$CardBorderColor: #F5F9FF;
$tabBtnColor: #fcfcfc;
$tabBtnFillColor: #ebf1fa;
$selectRowColor: #f9fbff;
$lightgrayColor:#F7F7F8;
$darkLilacColor:#9E679C;
$lightLilacColor:#C288CD;
$royalOrangeColor:#F49A47;
$purpleColor:#410A63;
$lightPurpleColor:#7962BA;
$electricPurpleColor:#BD00FF;
$tabsActiveColor:#383048;
$tagButtonBorderColor:#EFECF6;
$bgGradient: linear-gradient(180deg, #270067 48.96%, #8626C0 100%);
$bgBtn: linear-gradient(90deg, #3232A6 0%, #8626C0 100%);