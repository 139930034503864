@import "../../../../../assets/sass/variables";
.hedis-measure {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  &-container {
    padding-right: 12px;
    max-height: 280px;
    overflow-x: auto;
    &-right {
      .quick-links-list {
        margin-bottom: 20px;
        max-height: 78px;
        overflow-y: auto;
      }
      .bold {
        font-weight: 700;
      }
      .hedis-ques-ans {
        max-height: 150px;
        overflow-y: auto;
      }
    }
  }
  &-item {
    padding: 6px 10px;
    border-radius: 4px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    color: $purpleColor;
  }
}
