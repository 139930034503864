.App {
  text-align: center;
}

.light-mode {
  --font-color: #101212;
  --body-bg-color: #ffffff;
}
.dark-mode {
  --font-color: #cccccc;
  --body-bg-color: #101212;
}

.screenshot-blocker {
  display: none; /* Hide the overlay by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white; /* You can customize the background color */
  z-index: 9999; /* Make sure the overlay is on top of other elements */
}

.screenshot-blocker.active {
  display: block; /* Show the overlay when active class is applied */
}
