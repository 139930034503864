@use "../../../assets/sass/variables";

.ask-question {
  display: flex;
  gap: 15px;
  height: calc(100vh - 260px);
  &.collapse {
    .ask-question-right-holder {
      flex-basis: 0;
      padding: 0;
      border-right: 0;
    }
  }
  .ant-btn-primary {
    background: transparent;
  }
  .record-search {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    .ant-input {
      height: 42px;
      font-size: 14px;
      &-search-button {
        height: 42px;
      }
    }
  }
  &-left-conatiner {
    flex: 1 1 70%;
    display: flex;
    flex-direction: column;
  }
  .ask-question-right-container {
    flex: 1;
    h4 {
      line-height: 1;
    }
  }
  .ask-question-icon {
    padding-top: 3px;
    width: 12px;
    height: 15px;
    align-self: center;
  }
  .overlap-icon {
    align-items: center;
    display: flex;
    height: 70px;
    position: absolute;
    left: -22px;
    top: 50%;
    width: 22px;
    border: 1px solid #e7ecf4;
    border-radius: 30px 0 0 30px;
    background: #fff;
    border-right: 0;
    justify-content: center;
  }
  &-right-holder {
    position: relative;
    flex-basis: 30%;
    gap: 10px;
    display: flex;
    align-items: baseline;
    transition: all 0.5s ease;
    border: 1px solid #e7ecf4;
    border-radius: 4px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
    padding: 20px;

    .question-item {
      padding: 12px;
      border-radius: 4px;
      background: rgba($color: #f5f5f6, $alpha: 0.4);
      &-list {
        max-height: calc(100vh - 340px);
        height: 100%;
        overflow-y: auto;
      }
    }
  }
  &-search-bar {
    margin-top: auto;
  }
  &-result {
    margin-bottom: 30px;
    padding-right: 6px;

    .question-icon {
      width: 32px;
      height: 32px;
      padding: 4px;
      border: 1px solid variables.$purpleColor;
      border-radius: 4px;
    }
    &-ques,
    &-answer {
      display: grid;
      grid-template-columns: 34px 1fr 74px 60px;
      column-gap: 20px;
      padding: 16px 22px;
      align-items: center;
    }
    &-ques {
      align-items: center;
    }
    &-answer {
      background: rgba($color: #f5f5f6, $alpha: 0.4);
      &-img {
        width: 32px;
        height: 32px;
        padding: 7px 6px;
        background: variables.$purpleColor;
        border-radius: 4px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
