@use "../../assets/sass/variables";

.placeholder-text {
  font-size: 12px;
  text-align: left;
  margin: 10px;
  color: variables.$baseTextColor;
  opacity: 0.4;
  p {
    color: variables.$baseTextColor;
    // opacity: 0.4;
  }
}

.tabs {
  &.no-border {
    .ant-tabs-top {
      > .ant-tabs-nav {
        margin: 0px;
        &::before {
          border-bottom: 0px;
        }
      }
    }
  }
}

.biomarkers {
  .ant-table {
    .ant-table-tbody {
      max-height: 280px;
    }
  }
}

.gene-mutations {
  .ant-table {
    .ant-table-tbody {
      max-height: 357px;
    }
  }
}

.tile-heading{
  text-transform: uppercase;
  border-bottom: 2px solid variables.$borderColor;
  padding-bottom: 12px;
  margin-top: 50px;
}

.insight-card-title{
  font-family: Font-Bold;
  color: variables.$lightPurpleColor;
  margin-bottom: 15px;
  &.m-t-60{
    margin-top: 60px;
  }
}

.demo-card-conatiner{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px 20px;

  .demo-card-conatiner-title{
    font-family: Font-Regular;
  }

  p{
    font-size: 14px;
    font-family: Font-Semibold;
  }
  div {
    &:nth-child(6){
      grid-column: 1/3;
    }
  }
}

.vital-sign-conatiner{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px 20px;

  .vital-sign-title{
    font-family: Font-Regular;
  }

  p{
    font-size: 14px;
    font-family: Font-Semibold;
  }
// }
// .ask-question{
//   display: flex;
//   flex-direction: column;
//   height: calc(100% - 127px);

//   .ant-btn-primary{
//     background: transparent;
//   }
//   .record-search {
//     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
//     .ant-input{
//       height:42px;
//       font-size: 14px;
//       &-search-button{
//         height:42px;
//       }
//     }
//   }
//   &-search-bar{
//     margin-top: auto;
//     padding:20px 60px 0 76px;
//   }
//   &-result{
//     margin-bottom: 30px;
//     padding-right: 6px;

//     .question-icon{
//       width: 32px;
//       height:32px;
//       padding: 4px;
//       border: 1px solid variables.$purpleColor;
//       border-radius: 4px;
//     }
//     &-ques,
//     &-answer{
//       display: grid;
//       grid-template-columns: 34px 1fr 84px;
//       column-gap: 20px;
//       padding:16px 22px;
//     }
//     &-ques{
//       align-items: center;
//     }
//     &-answer{
//       background: rgba($color: #F5F5F6, $alpha: 0.4);
//       &-img{
//         width: 32px;
//         height: 32px;
//         padding: 7px 6px;
//         background: variables.$purpleColor;
//         border-radius: 4px;
//       }
//       p {
//         font-size: 14px;
//         line-height: 20px;
//       }
//     }
//   }
}